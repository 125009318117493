import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import voucherStoreModule from '../voucherStoreModule';

export default function useVoucherList() {
  const APARTMENT_STORE_MODULE_NAME = "voucher";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, voucherStoreModule);
  }

  // UnRegister on leave
  // onUnmounted(() => {
  //   if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
  //     store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
  //   }
  // });
  // Use toast
  const toastification = toast();

  const blankItem = {
    name: '',
    code: '',
    type: 'discount',
    discount_type: 'percent',
    discount_percent: 0,
    max_discount: 0,
    min_money_apply: 0,
    pricings: [],
    is_apply_all: true,
    start_time: moment().format('DD-MM-YYYY'),
    end_time: null,
    status: true,
    number_of_uses: 0,
    discount_money: 0,
    transaction_times: 0,
    transaction_expire: null,
    customer_groups: [],
    customers: [],
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Thao tác',
      field: 'action',
      width: '100px',
      tdClass: 'text-center',
      sortable: false,
    },
    {
      label: 'Voucher',
      field: 'voucher',
    },
    {
      label: 'Cơ sở áp dụng',
      field: 'facility',
    },
    {
      label: 'Gói dịch vụ',
      field: 'pricings',
    },
    {
      label: 'Đã sử dụng',
      field: 'used',
      tdClass: 'text-center',
      thClass: 'text-center',
    },
    {
      label: 'Từ ngày',
      field: 'start_time',
    },
    {
      label: 'Đến ngày',
      field: 'end_time',
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const status = ref(null);
  const province = ref(null);
  const department = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    start: 0,
    length: 50,
    status: "-1",
    draw: "1",
    specialist: "-1",
    subSpecialist: "-1",
  });

  //   API Call

  const fetchDoctors = () => {
    store
      .dispatch('voucher/fetchVouchers', serverParams.value)
      .then(response => {
        const { data, recordsTotal } = response.data;
        totalRecords.value = recordsTotal;
        rows.value = data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchDoctors();
  };
  const importData = file => {
    const formData = new FormData();
    formData.append('file', file);
    store
      .dispatch('apartment/importApartments', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('apartment/exportApartments', serverParams.value)
      .then(response => {
        console.log(response.data);
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteApartments = apartments => {
    store
      .dispatch('apartment/deleteApartments', {
        ids: apartments.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ start: (params.currentPage - 1) * serverParams.value.length });
  };

  const onPerPageChange = params => {
    updateParams({ length: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(status, val => {
    updateParams({ status: val ? val.value : "-1" });
  });

  // UI
  const resolveStatusVariant = val => {
    if (val === 'active') return { variant: 'primary', title: "Kích hoạt", icon: 'SmileIcon' };
    if (val === 'inactive') return { variant: 'warning', title: "Chưa kích hoạt", icon: 'MehIcon' };
    return { variant: 'danger', title: "Khóa", icon: 'FrownIcon' };
  };

  const resolveFamilyDoctorVariant = val => {
    if (val) return { variant: 'primary', title: "Có", icon: 'CheckIcon' };
    return { variant: 'danger', title: "Không", icon: 'MinusIcon' };
  };

  const resolveTimeVariant = val => {
    const time = moment(val);
    if (time.isValid()) {
      return time.format('DD/MM/YYYY');
    }
    return '';
  };

  return {
    analytics,
    item,
    columns,
    rows,
    province,
    department,
    status,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchData,
    deleteApartments,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
    resolveStatusVariant,
    resolveFamilyDoctorVariant,
    resolveTimeVariant,
  };
}
