import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVouchers(ctx, data) {
      return useJwt.getVouchers(data).then(response => response);
    },
    getApartmentLayout(ctx, params) {
      return useJwt.getApartmentLayout(params).then(response => response);
    },
    getApartmentDetail(ctx, id) {
      return useJwt.getApartmentDetail(id).then(response => response);
    },
    createVoucher(ctx, data) {
      return useJwt.createVoucher(data).then(response => response);
    },
    updateVoucher(ctx, data) {
      return useJwt.updateVoucher(data).then(response => response);
    },
    importApartments(ctx, data) {
      return useJwt.importApartments(data).then(response => response);
    },
    exportApartments(ctx, data) {
      return useJwt.exportApartments(data).then(response => response);
    },
    deleteApartments(ctx, data) {
      return useJwt.deleteApartments(data).then(response => response);
    },
  },
};
